import { apolloClient } from '../../utils/apollo';
import { departmentQuery } from '@graphql/queries/department.js';
import { departmentWeatherQuery } from '@graphql/queries/departmentWeather.js';
import { myArchiveScreenQuery } from '@graphql/queries/myArchiveScreen.js';
import store from '@store';
import { get, cloneDeep } from 'lodash';
import { getModuleData } from '@utils/modules';
import * as Sentry from '@sentry/vue';

const state = {
  loginKey: '',
  color: '',
  icon: '',
  id: null,
  hasValidExternalModule: null,
  institutionId: null,
  presentation: {
    description: '',
    image: '',
  },
  name: '',
  settings: {
    screenConfig: {
      gridSize: null,
      modules: [],
      footerConfig: [],
      timeout: null,
      slideshow: {
        duration: null,
      },
    },
    appConfig: {
      modules: [],
    },
  },
  weatherForecast: null,
  archive: {},
};

const mutations = {
  SET_HAS_VALID_EXTERNAL_MODULE(state, isExternalActivitiesValid) {
    state.hasValidExternalModule = isExternalActivitiesValid;
  },
  SET_DEPARTMENT(state, department) {
    Object.assign(state, department);
  },
  SET_WEATHER_FORECAST(state, weatherForecast) {
    state.weatherForecast = weatherForecast;
  },
  SET_MY_ARCHIVE(state, archive) {
    state.archive = archive;
  },
  SET_DEPARTMENT_ID(state, id) {
    state.id = id;
  },
};

const actions = {
  checkIfValidExternalModule({ state, commit }) {
    if (!store.getters['institution/didLoadExternalConnections']) {
      return;
    }

    const hasExternalActivitiesModule =
      state.settings?.screenConfig?.footerConfig?.includes(
        'EXTERNAL_ACTIVITIES'
      );

    const hasExternalConnections =
      store.getters['institution/externalConnections']?.length > 0;

    if (hasExternalActivitiesModule && !hasExternalConnections) {
      const errMsg = 'Found external footer module without valid connection';

      Sentry.captureException(new Error(errMsg), {
        tags: {
          config_error: errMsg,
        },
        extra: { departmentId: state?.id },
      });
    }

    commit(
      'SET_HAS_VALID_EXTERNAL_MODULE',
      hasExternalActivitiesModule && hasExternalConnections
    );
  },

  async getDepartment({ commit, dispatch }) {
    if (!state.id) {
      throw new Error('No department id provided');
    }

    await apolloClient
      .query({
        query: departmentQuery,
        variables: {
          id: state.id,
        },
      })
      .then((response) => {
        const department = cloneDeep(get(response, 'data.department', []));
        const moduleData = getModuleData();

        const modules = department.settings.screenConfig.modules.map(
          (module) => {
            const rowValues = module.rowSpan.split('/');
            const colValues = module.colSpan.split('/');
            const name =
              moduleData[module.type].name || moduleData[module.type].component;

            return {
              type: module.type,
              name,
              colorId: moduleData[module.type].colorId,
              colorHex: moduleData[module.type].colorHex,
              icon: moduleData[module.type].icon,
              row: {
                start: rowValues[0],
                end: rowValues[1],
              },
              col: {
                start: colValues[0],
                end: colValues[1],
              },
              frHeight: rowValues[1] - rowValues[0],
              frWidth: colValues[1] - colValues[0],
            };
          }
        );

        department.settings.screenConfig.modules = modules;
        commit('SET_DEPARTMENT', department);
        dispatch('checkIfValidExternalModule');
      })
      .catch((error) => {
        console.log('Error getting department', error);
      })
      .finally(() => {
        Sentry.setUser({
          id: state.id,
          departmentSettings: state.settings,
          institutionId: state.institutionId,
        });
      });
  },
  async setDepartmentId({ commit }, id) {
    commit('SET_DEPARTMENT_ID', id);
  },
  async switchDepartment({ commit }, department) {
    commit('general/SET_LOADING', true, { root: true });

    if (!department) {
      sessionStorage.removeItem('temp_login_key');
      sessionStorage.removeItem('temp_departments');
    } else {
      sessionStorage.setItem('temp_login_key', department.loginKey);
    }

    window.localStorage.clear();
    window.location.href = '/';
  },
  async getWeather({ commit }) {
    await apolloClient
      .query({
        query: departmentWeatherQuery,
        variables: {
          id: state.id,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        commit('SET_WEATHER_FORECAST', response);
      })
      .catch((error) => {
        console.error('Error fetching weather', error);
      });
  },
  async getArchive({ commit }, id) {
    await apolloClient
      .query({
        query: myArchiveScreenQuery,
        variables: {
          profile: {
            profileType: 'DEPARTMENT',
            profileId: id,
          },
        },
      })
      .then((response) => {
        commit('SET_MY_ARCHIVE', response.data.myArchiveScreen);
      })
      .catch((error) => {
        console.log('Error getting my archive', error);
      });
  },
};

const getters = {
  id: (state) => state.id,
  name: (state) => state.name,
  color: (state) => state.color,
  settings: (state) => state.settings,
  idleTimeout: (state) => state.settings.screenConfig.timeout,
  weatherForecast: (state) => state.weatherForecast,
  archive: (state) => state.archive,
  presentation: (state) => state.presentation,
  loginKey: (state) => state.loginKey,
  current: (state) => state,
  hasValidExternalModule: (state) => state.hasValidExternalModule,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
