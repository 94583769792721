import gql from 'graphql-tag';

export const myArchiveScreenQuery = gql`
query myArchiveScreen ($profile: ScreenProfile!) {
  myArchiveScreen (profile: $profile) {
    documents {
      id
      title
      url
    }
    pictures {
      id
      source
    }
    videos {
      id
      provider
      thumbnail
      url
  }
    galleries {
      id
      title
      coverImage {
        id
        source
      }
      pictures {
        id
        source
      }
      videos {
        id
        provider
        thumbnail
        url
      }
      media {
        ... on GalleryPicture {
          id
          source
        }
        ... on GalleryVideo {
          id
          url
          thumbnail
        }
      }
    }
  }
}`