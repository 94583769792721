<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  margin: {
    type: String,
    default: 'mb-14',
  },
});

const institutionName = computed(() => {
  return store.getters['institution/name'];
});
</script>

<template>
  <div class="relative content flex items-start w-full" :class="margin">
    <fa-icon
      :aria-label="$t('global.institution')"
      class="mr-6"
      :icon="['fal', 'hotel']"
    />
    <span :aria-label="title ?? institutionName" class="font-light">{{
      title ?? institutionName
    }}</span>
  </div>
</template>
