<script setup>
import { useStore } from 'vuex';
import { getModuleData } from '@utils/modules';
import { computed, onBeforeMount, ref } from 'vue';
import ModuleBaseView from '@components/moduleBaseView/ModuleBaseView.vue';

const store = useStore();

const isLoading = ref(true);

const hasValidExternalModule = computed(
  () => store.getters['department/hasValidExternalModule']
);

const moduleData = computed(() =>
  hasValidExternalModule.value
    ? store.getters['activities/internal']
    : store.getters['activities/all']
);

const { colorHex, icon, emptyKey, routeName, entryRouterName } =
  getModuleData().ACTIVITIES;

onBeforeMount(async () => {
  await store.dispatch('activities/getActivities', { useCache: true });
  isLoading.value = false;
});
</script>

<template>
  <div>
    <ModuleBaseView
      v-if="!isLoading"
      :moduleName="emptyKey"
      :moduleData="moduleData"
      :colorHex="colorHex"
      :icon="icon"
      :emptyKey="emptyKey"
      :routeName="routeName"
      :entryRouterName="entryRouterName"
    />
  </div>
</template>
