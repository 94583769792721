import { apolloClient } from '../../utils/apollo';
import store from '@store';
import { journeyPlannerQuery } from '@graphql/queries/journeyPlanner.js';

const state = {
  all: [],
  isLoading: false,
  selectedFilter: {},
};

const mutations = {
  SET_DEFAULT_TRRAVEL_PLAN(state, journeyPlan) {
    state.all = journeyPlan;
  },
  SET_ISLOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_SELECTED_FILTER(state, filter) {
    state.selectedFilter = filter;
  },
};

const actions = {
  async getJourneyPlan({ commit }) {
    commit('SET_ISLOADING', true);
    const departmentId = store.getters['department/id'];

    if (!departmentId) {
      throw new Error('No department id provided');
    }

    await apolloClient
      .query({
        query: journeyPlannerQuery,
        variables: {
          id: departmentId,
          delay: state.selectedFilter.value,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        commit(
          'SET_DEFAULT_TRRAVEL_PLAN',
          JSON.parse(response.data.departmentTravelPlan)
        );
        commit('SET_ISLOADING', false);
      })
      .catch((error) => {
        console.log('Error getting travel plan', error);
        commit('SET_ISLOADING', false);
      });
  },
  setSelectedFilter({ commit }, filter) {
    commit('SET_SELECTED_FILTER', filter);
  },
};

const getters = {
  all: (state) => state.all,
  isLoading: (state) => state.isLoading,
  selectedFilter: (state) => state.selectedFilter,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
