<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const props = defineProps({
  icons: {
    type: Array,
    default: () => [],
  },
  mediaType: {
    type: String,
    default: 'pictures',
  },
  color: {
    type: String,
    default: '',
  },
  iconsPositionType: {
    type: String,
    default: 'route',
  },
});

const moduleColor = computed(() => {
  return props.color.length ? props.color : route.meta?.color;
});

const filteredIcons = computed(() => {
  return props.icons.filter((icon) => icon.amount > 0);
});

function getIconBackgroundColor(icon) {
  return props.mediaType === icon.name ? `bg-${moduleColor.value}` : 'bg-white';
}

function getIconColor(icon) {
  return props.mediaType === icon.name
    ? 'text-white'
    : `text-${moduleColor.value}`;
}
</script>

<template>
  <div
    class="mediaIcons absolute right-10 h-20 flex justify-end w-full"
    :class="{
      '-top-28': iconsPositionType === 'route',
      'top-80': iconsPositionType === 'overlay',
    }"
  >
    <div
      v-for="(icon, index) in filteredIcons"
      :key="index"
      class="rounded-full w-20 h-20 ml-6 flex justify-center items-center relative border-2 border-white"
      :class="getIconBackgroundColor(icon)"
      @click="$emit('media-selected', icon.name)"
    >
      <fa-icon
        :icon="['fal', icon.fa]"
        class="text-4xl"
        :class="getIconColor(icon)"
      />

      <div
        class="rounded-full border-white border-2 text-white absolute -right-1 -bottom-1 w-8 h-8 flex items-center justify-center text-xl font-bold"
        :class="`bg-${moduleColor}`"
      >
        {{ icon.amount }}
      </div>
    </div>
  </div>
</template>
