<script setup>
import { computed } from 'vue';
import i18n from '@i18n';

const { isCancelled, isToday, isNow } = defineProps({
  isCancelled: {
    type: Boolean,
    required: false,
    default: false,
  },
  isToday: {
    type: Boolean,
    required: false,
    default: false,
  },
  isNow: {
    type: Boolean,
    required: true,
  },
});

const styles = computed(() => {
  if (isCancelled) {
    return 'bg-cancelled text-white';
  }
  if (isToday && !isNow) {
    return 'bg-white';
  }
  if (isNow) {
    return 'bg-white';
  }
  return '';
});

const statusText = computed(() => {
  if (isCancelled) {
    return i18n.global.t('global.cancelled');
  }
  if (isToday && !isNow) {
    return i18n.global.t('global.today');
  }
  if (isNow) {
    return i18n.global.t('global.now');
  }
  return null;
});
</script>

<template>
  <div
    v-if="statusText"
    :class="`absolute ${styles} ring-inset ring-2 ring-neutral-200 shadow-mini left-5 bottom-[140px] transform translate-y-1/2 px-3 py-2 rounded-3xl`"
  >
    <p class="flex items-center uppercase h500">
      <fa-icon :icon="['fas', 'calendar']" class="size-[16px] mr-2" />
      {{ statusText }}
    </p>
  </div>
</template>
