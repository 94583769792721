import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPhotoFilm,
  faKey,
  faRunning,
  faBus,
  faHome,
  faCalendar,
  faIdCard,
  faClock,
  faXmark,
  faCalendarPlus,
  faSunBright,
  faArrowLeft,
  faTableCellsLarge,
  faTableCells,
  faGrid,
  faList,
  faLightbulbSlash,
  faPlayCircle,
  faHouseUser,
  faCheck,
  faChevronDown,
  faChevronUp,
  faSignOut,
  faSunrise,
  faSun,
  faMoonStars,
  faSunset,
  faBan,
} from '@fortawesome/pro-regular-svg-icons';

// REGULAR

library.add(
  faSunset,
  faPhotoFilm,
  faKey,
  faRunning,
  faBus,
  faHome,
  faCalendar,
  faIdCard,
  faClock,
  faXmark,
  faCalendarPlus,
  faSunBright,
  faArrowLeft,
  faTableCellsLarge,
  faTableCells,
  faGrid,
  faList,
  faLightbulbSlash,
  faPlayCircle,
  faHouseUser,
  faCheck,
  faChevronDown,
  faChevronUp,
  faSignOut,
  faSunrise,
  faSun,
  faMoonStars,
  faBan
);
