<script setup>
import { computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import { getModuleData } from '@utils/modules';

const store = useStore();

const COMPONENT_MAP = {
  Slideshow: defineAsyncComponent(
    () => import('@components/widgets/Slideshow.vue')
  ),
  JourneyPlannerWidget: defineAsyncComponent(
    () => import('@components/widgets/JourneyPlannerWidget.vue')
  ),
  WeatherWidget: defineAsyncComponent(
    () => import('@components/widgets/WeatherWidget.vue')
  ),
  WorkScheduleWidget: defineAsyncComponent(
    () => import('@components/widgets/WorkScheduleWidget.vue')
  ),
  ActivityWidget: defineAsyncComponent(
    () => import('@components/widgets/ActivityWidget.vue')
  ),
  ActivityWidgetExternal: defineAsyncComponent(
    () => import('@components/widgets/ActivityWidgetExternal.vue')
  ),
  BookingsWidget: defineAsyncComponent(
    () => import('@components/widgets/BookingsWidget.vue')
  ),
  MealPlanWidget: defineAsyncComponent(
    () => import('@components/widgets/MealPlanWidget.vue')
  ),
  MeetingsWidget: defineAsyncComponent(
    () => import('@components/widgets/MeetingsWidget.vue')
  ),
  NewsWidget: defineAsyncComponent(
    () => import('@components/widgets/NewsWidget.vue')
  ),
  GalleryWidget: defineAsyncComponent(
    () => import('@components/widgets/GalleryWidget.vue')
  ),
};

const settings = computed(() => store.getters['department/settings']);
const idleCount = computed(() => store.getters['general/idleCount']);

const widgets = computed(() => {
  return settings.value?.screenConfig?.modules || [];
});

const widgetData = computed(() => {
  return getModuleData();
});

function getGridStyles(widget) {
  return {
    'grid-column': widget.col.start,
    'grid-column-end': widget.col.end,
    'grid-row-start': widget.row.start,
    'grid-row-end': widget.row.end,
  };
}

function hasDepartmentSelector(widget) {
  return ['meals', 'workShedule'].includes(widget);
}
</script>

<template>
  <div class="startPage">
    <div
      v-if="widgets.length"
      class="startPageGrid grid gap-6 p-6 h-full bg-neutral-100 pb-10"
    >
      <div
        v-for="(widget, index) in widgets"
        :key="index"
        :class="[
          `grid-${settings.screenConfig.gridSize}`,
          { hasSelector: hasDepartmentSelector(widget.name) },
          `frWidth${widget.frWidth}`,
        ]"
        class="h-full relative"
        :style="getGridStyles(widget)"
      >
        <component
          :is="COMPONENT_MAP[widgetData[widget.type].component]"
          :moduleName="widgetData[widget.type].emptyKey"
          :class="widgetData[widget.type].emptyKey"
          :widget="widgetData[widget.type]"
          :width="widget.frWidth"
          :key="idleCount"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.startPage {
  .startPageGrid {
    height: 100%;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-rows: minmax(0, 1fr);
    grid-auto-flow: column;

    .hasSelector {
      .departmentSelector {
        filter: drop-shadow(0 5px 4px rgba(0, 0, 0, 0.25));
        right: 24px;
      }

      &.frWidth1 {
        .VueCarousel-pagination {
          width: 60%;
        }
      }

      &.frWidth2 {
        .departmentSelector {
          width: 42%;
        }

        .VueCarousel-pagination {
          width: 45%;
        }

        .VueCarousel-dot-container {
          justify-content: flex-end !important;
        }
      }
    }
  }
}
</style>
