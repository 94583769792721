import gql from 'graphql-tag';

export const GalleryEntryQuery = gql`
  query galleryEntry($id: ID!) {
    galleryEntry(id: $id) {
      id
      title
      category {
        id
        title
        description
      }
      coverImage {
        id
        description
        blurhash
        source
      }
      pictures {
        id
        description
        blurhash
        source
        favorited
        inMyArchive
      }
      videos {
        id
        provider
        url
        description
        blurhash
        favorited
        thumbnail
      }
      media {
        ... on GalleryPicture {
          id
          source
        }
        ... on GalleryVideo {
          id
          url
          thumbnail
        }
      }
      settings {
        dreambrokerUrl
      }
    }
  }
`;
