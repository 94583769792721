<script setup>
import { default as ButtonEl } from '@components/shared/Button.vue';
import { hexToRgbA } from '@utils/helpers.js';
import { computed } from 'vue';

const emit = defineEmits(['previous', 'next', 'autoplay-toggled', 'close']);

const props = defineProps({
  totalSlides: {
    type: Number,
    default: 0,
  },
  currentSlide: {
    type: Number,
    default: 1,
  },
  mediaType: {
    type: String,
    default: '',
  },
  autoplay: {
    type: Boolean,
    default: true,
  },
  hideNavigation: {
    type: Boolean,
    default: false,
  },
});

const progressBackColor = computed(() => {
  return hexToRgbA('#fff', 0.1);
});

const progressFrontColor = computed(() => {
  return hexToRgbA('#fff', 0.2);
});

const progressPercent = computed(() => {
  return `${(props.currentSlide / props.totalSlides) * 100}%`;
});

function goToPrevious() {
  emit('previous');
}

function goToNext() {
  emit('next');
}

function toggleAutoplay() {
  emit('autoplay-toggled');
}
</script>

<template>
  <div
    class="carouselControls flex flex-row items-center justify-between w-full px-32 h-32"
  >
    <!-- Autoplay -->
    <div class="text-white w-2/5">
      <div v-if="mediaType === 'pictures'">
        <!-- Autoplay started -->
        <div v-if="autoplay" class="w-48">
          <div
            class="rounded-md h-5 p-1 w-full mb-2"
            :style="{ backgroundColor: progressBackColor }"
          >
            <div
              class="rounded-tl-md rounded-bl-md h-full transition-all"
              :class="
                progressPercent === '100%'
                  ? 'rounded-tr-md rounded-br-md'
                  : 'rounded-tr-none rounded-br-none'
              "
              :style="{
                backgroundColor: progressFrontColor,
                width: progressPercent,
              }"
            />
          </div>

          <button-el
            class="content bold"
            text="global.stop"
            icon="square"
            background-color="error"
            @click="toggleAutoplay()"
          />
        </div>

        <!-- Autoplay stopped -->
        <div v-else class="flex items-center" @click="toggleAutoplay()">
          <fa-icon
            class="text-white text-3xl mr-2"
            :icon="['far', 'play-circle']"
          />
          <span class="content bold">{{ $t('global.autoplay') }}</span>
        </div>
      </div>
    </div>

    <!-- Navigation -->
    <div
      v-if="!hideNavigation"
      class="text-white text-center w-1/3 flex justify-between items-center"
    >
      <div
        class="w-12 h-12 flex items-center justify-center"
        @click="goToPrevious"
      >
        <fa-icon
          class="text-white content light text-2xl"
          :icon="['fa', 'chevron-left']"
        />
      </div>

      <span class="content">{{ currentSlide }} / {{ totalSlides }}</span>

      <div class="w-12 h-12 flex items-center justify-center" @click="goToNext">
        <fa-icon
          class="text-white text-2xl content light"
          :icon="['fa', 'chevron-right']"
        />
      </div>
    </div>

    <!-- Close -->
    <div class="w-2/5 flex justify-end">
      <button-el
        class="content bold"
        text="global.close"
        icon="times"
        :padding="false"
        text-color="white"
        @click="$emit('close')"
      />
    </div>
  </div>
</template>
