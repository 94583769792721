<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { isEmpty, capitalize } from 'lodash';
import { useRoute } from 'vue-router';
import CoverView from '@views/shared/CoverView.vue';
import MediaIcons from '@components/shared/MediaIcons.vue';
import MediaPreview from '@views/overlays/MediaPreview.vue';
import MediaList from '@views/shared/MediaList.vue';
import TTS from '@components/shared/Tts.vue';
import { getStartDay } from '@utils/helpers.js';
import i18n from '@i18n';
import TitleBlock from '@components/shared/blocks/TitleBlock.vue';
import PictogramsBlock from '@components/shared/blocks/PictogramsBlock.vue';
import DescriptionBlock from '@components/shared/blocks/DescriptionBlock.vue';

const store = useStore();
const route = useRoute();

const mediaType = ref('');
const selectedMediaIndex = ref(-1);
const hideMediaPreview = ref(false);
const showMediaList = ref(false);

const news = computed(() => {
  return store.getters['news/all'];
});

const moduleColor = computed(() => {
  return route?.meta?.color;
});

const newsEntry = computed(() => {
  return news.value.find((newsEntry) => newsEntry.id === route.params.id);
});

const mediaIcons = computed(() => {
  return [
    {
      name: 'pictures',
      fa: 'image',
      amount: newsEntry.value.pictures.length,
    },
    {
      name: 'videos',
      fa: 'film',
      amount: newsEntry.value.videos.length,
    },
    {
      name: 'documents',
      fa: 'file-alt',
      amount: newsEntry.value.documents.length,
    },
  ];
});

const mediaItems = computed(() => {
  let items;

  switch (mediaType.value) {
    case 'pictures':
      items = newsEntry.value.pictures;
      break;
    case 'videos':
      items = newsEntry.value.videos;
      break;
    case 'documents':
      items = newsEntry.value.documents;
      break;
    default:
      items = [];
  }
  return items;
});

const selectedMediaItem = computed(() => {
  return mediaItems.value[selectedMediaIndex.value];
});

const showMediaPreview = computed(() => {
  return !isEmpty(selectedMediaItem.value) && !hideMediaPreview.value;
});

const published = computed(() => {
  return `${i18n.global.t('news.published')} ${getStartDate(newsEntry.value.startDate)}`;
});

function getStartDate(dateString) {
  const startDay = getStartDay(dateString, true);
  return startDay.includes('.')
    ? capitalize(i18n.global.t(startDay))
    : capitalize(startDay);
}

function closeMediaList() {
  mediaType.value = '';
  selectedMediaIndex.value = -1;
  showMediaList.value = false;
}

function mediaIconSelected(payload) {
  mediaType.value = payload;

  if (mediaItems.value.length === 1) {
    // if only one item, go straight to media-preview
    selectedMediaIndex.value = 0;
    hideMediaPreview.value = false;
    return;
  }

  showMediaList.value = true;
}
</script>

<template>
  <div class="newsEntry">
    <media-preview
      v-if="showMediaPreview"
      :selected-item-index="selectedMediaIndex"
      :media-type="mediaType"
      :items="mediaItems"
      @close-preview="selectedMediaIndex = -1"
    />

    <cover-view
      v-if="newsEntry"
      :image-url="newsEntry.coverImage.source"
      :gradient="true"
      :module-color="moduleColor"
      :margin-top="60"
    >
      <div class="newsEntryContent tts-content pt-20">
        <media-icons
          :icons="mediaIcons"
          :media-type="mediaType"
          :amount="mediaItems.length"
          @media-selected="mediaIconSelected"
        />
        <TTS entity-type="NEWS" />

        <div v-if="!showMediaList">
          <TitleBlock margin="pt-14" :text="newsEntry.title"></TitleBlock>

          <div class="text-3xl font-light pt-6 mb-14" :aria-label="published">
            {{ published }}
          </div>

          <PictogramsBlock
            v-if="newsEntry.pictograms.length"
            :pictograms="newsEntry.pictograms"
          />

          <DescriptionBlock :description="newsEntry.body" />
        </div>

        <div v-else>
          <media-list
            :items="mediaItems"
            :media-type="mediaType"
            :can-close="true"
            class="pt-20"
            @close="closeMediaList"
            @item-selected="selectedMediaIndex = $event"
          />
        </div>
      </div>
    </cover-view>
  </div>
</template>

<style lang="scss">
.newsEntry {
  .newsEntryContent {
    > *:not(.mediaIcons) {
      padding: 0 100px;
    }
  }
}
</style>
