import { createI18n } from 'vue-i18n';

import da from '@/i18n/da.json';
import de from '@/i18n/de.json';
import no from '@/i18n/no.json';

const messages = {
  da,
  de,
  no,
};

const numberFormats = {
  da: {
    currency: {
      style: 'currency',
      currency: 'DKK',
    },
  },
  de: {
    currency: {
      style: 'currency',
      currency: 'EUR',
    },
  },
  no: {
    currency: {
      style: 'currency',
      currency: 'NOK',
    },
  },
};

const i18n = createI18n({
  locale: 'da',
  messages,
  numberFormats,
  silentTranslationWarn: true,
  globalInjection: true,
  legacy: true,
});

export default i18n;
