import gql from "graphql-tag";

export const bookSlottedResourceScreenMutation = gql`
mutation booking ($participants: [ProfileInput!]!, $bookingSlot: BookingResourceBookingSlot!) {
  booking {
    bookSlottedResourceScreen (participants: $participants, bookingSlot: $bookingSlot) {
        id
        message
        success
    }
  }
}`

