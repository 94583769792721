<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import VideoSlideshow from '@components/shared/VideoSlideshow.vue';
import ImageSlideshow from '@components/shared/ImageSlideshow.vue';
import DocumentSlideshow from '@components/shared/DocumentSlideshow.vue';
import i18n from '@i18n';

const store = useStore();
const emit = defineEmits(['close-preview']);

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
  },
  selectedItemIndex: {
    type: Number,
    default: 0,
  },
  mediaType: {
    type: String,
    default: '',
  },
  allowArchiveRemove: {
    type: Boolean,
    default: false,
  },
});

const opt = ref('');
const archiveCurrentResourceId = ref(-1);

const activeProfile = computed(() => store.getters['profiles/activeProfile']);
const pinValidation = computed(() => store.getters['profiles/pinValidation']);
const selectedProfile = computed(() => store.getters['profiles/activeProfile']);

watch(pinValidation, (pinData) => {
  if (!pinData.validating) return;

  const archiveData = {
    resourceId: archiveCurrentResourceId.value,
    profile: {
      profileId: activeProfile.value.id,
      profileType: activeProfile.value.type,
      pin: pinData.pin.toString(),
    },
    opt: opt.value,
  };

  store.dispatch('galleries/archiveAddScreen', archiveData);

  if (opt.value === 'out') {
    setTimeout(() => {
      emit('close-preview', mediaType.value);
    }, 500);
  }
});

function archiveAdd(data) {
  archiveCurrentResourceId.value = data.id;

  opt.value = 'in';

  const pinOverlayData = {
    title: i18n.global.t(`media.addToMy.${data.mediaType}`),
    titleSuccess: i18n.global.t(`media.addedToMy.${data.mediaType}`),
    opt: opt.value,
  };

  showOverlay(pinOverlayData);
}

async function archiveRemove(data) {
  archiveCurrentResourceId.value = data.id;

  opt.value = 'out';
  const pinOverlayData = {
    title: i18n.global.t(`media.removeFromMy.${data.mediaType}`),
    titleSuccess: i18n.global.t(`media.removedFromMy.${data.mediaType}`),
    opt: opt.value,
  };
  await store.dispatch('profiles/setActiveProfile', selectedProfile.value);
  await store.dispatch('general/setActiveOverlay', {
    name: 'enter-pin',
    data: pinOverlayData,
  });
}

function showOverlay(pinOverlayData) {
  store.dispatch('general/setActiveOverlay', {
    name: 'profile-select',
    data: pinOverlayData,
  });
}
</script>

<template>
  <transition
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__fadeOut"
    :duration="800"
  >
    <div
      class="media-preview w-full bg-charcoal-opacity z-30 fixed flex flex-col justify-center"
      :class="{ 'pt-48': mediaType !== 'documents' }"
    >
      <VideoSlideshow
        v-if="mediaType === 'videos'"
        :allowArchiveRemove="allowArchiveRemove"
        :videos="items"
        :selectedItemIndex="selectedItemIndex"
        @archive-add="archiveAdd"
        @archive-remove="archiveRemove"
        @close-preview="$emit('close-preview')"
      />

      <ImageSlideshow
        v-if="mediaType === 'pictures' || mediaType === 'galleryPictures'"
        :pictures="items"
        :allowArchiveRemove="allowArchiveRemove"
        :selectedItemIndex="selectedItemIndex"
        @archive-add="archiveAdd"
        @archive-remove="archiveRemove"
        @close-preview="$emit('close-preview', mediaType)"
      />

      <DocumentSlideshow
        v-if="mediaType === 'documents'"
        :documents="items"
        :allowArchiveRemove="allowArchiveRemove"
        :selectedItemIndex="selectedItemIndex"
        @close-preview="$emit('close-preview')"
        @archive-add="archiveAdd"
        @archive-remove="archiveRemove"
      />
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.media-preview {
  transition: none;
  height: calc(100% - 280px);
  top: 0;
}
</style>
