<script setup>
import { ref } from 'vue';
import { hexToRgbA } from '@utils/helpers.js';

defineProps({
  icon: {
    type: String,
    default: '',
  },
  options: {
    type: Array,
    default: () => [],
  },
  selectedDepartment: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(['option-selected']);

const showOptionSelect = ref(false);

function onClickoutside() {
  if (!showOptionSelect.value) return;
  showOptionSelect.value = false;
}

function optionSelected(option) {
  showOptionSelect.value = false;
  emit('option-selected', option);
}

function getBackgroundColorOpaqueHex(option) {
  return hexToRgbA(option.color, 0.05);
}
</script>

<template>
  <div
    class="w-96 text-3xl rounded-lg shadow-lg p-3 font-bold bg-white absolute"
    @click.stop
  >
    <div class="overflow-y-scroll max-h-96">
      <div
        v-for="(option, index) in options"
        v-show="showOptionSelect"
        :key="index"
        v-clickoutside="{
          excludeString: '.selectDepartment, .selectDepartment>*',
          handler: onClickoutside,
        }"
        @click="optionSelected(option)"
      >
        <div
          v-if="option.id !== selectedDepartment.id"
          class="relative w-full inline-flex items-center p-2 mb-2 rounded rounded-sm border border-gray-300"
        >
          <span
            v-if="icon"
            class="icon rounded-lg mr-3 flex justify-center items-center w-16 h-16"
            :style="[{ backgroundColor: option.color }]"
          >
            <fa-icon class="text-white text-2xl" :icon="['far', `${icon}`]" />
          </span>

          <span class="truncate flex items-center text-2xl">{{
            $t(option.name)
          }}</span>
        </div>
      </div>
    </div>

    <div
      class="rounded-lg p-2 flex items-center justify-between h-20"
      :style="[
        {
          backgroundColor: getBackgroundColorOpaqueHex(selectedDepartment),
        },
      ]"
      @click="showOptionSelect = !showOptionSelect"
    >
      <div class="flex">
        <span
          v-if="icon"
          class="icon rounded-lg mr-3 flex justify-center items-center w-16 h-16"
          :style="[{ backgroundColor: selectedDepartment.color }]"
        >
          <fa-icon class="text-white text-2xl" :icon="['far', `${icon}`]" />
        </span>

        <span class="truncate flex items-center text-2xl">{{
          selectedDepartment.name
        }}</span>
      </div>

      <span
        class="icon flex justify-center items-center"
        :style="[{ color: selectedDepartment.color }]"
      >
        <fa-icon class="text-2xl" :icon="['far', 'check']" />
      </span>
    </div>
  </div>
</template>
