import colors from './colors';

export function getModuleData() {
  return {
    ACTIVITIES: {
      component: 'ActivityWidget',
      name: 'activities',
      routeName: 'activities',
      entryRouterName: 'ACTIVITIES_ENTRY',
      icon: 'running',
      colorId: 'activities-light-default',
      colorHex: colors.ACTIVITIES,
      emptyKey: 'ACTIVITIES',
    },
    BOOKING: {
      component: 'BookingsWidget',
      entryRouterName: 'BOOKING_ENTRY', // BOOKING_RESOURCE_ENTRY
      name: 'booking',
      routeName: 'bookings/booking',
      icon: 'key',
      colorId: 'booking-light-default',
      colorHex: colors.BOOKING,
      emptyKey: 'BOOKING',
    },
    EXTERNAL_ACTIVITIES: {
      isExternal: true,
      component: 'ActivityWidgetExternal',
      name: 'externalActivities',
      routeName: 'externalActivities',
      entryRouterName: 'EXTERNAL_ACTIVITIES_ENTRY',
      icon: 'running',
      colorId: 'externalActivities-light-default',
      colorHex: colors.EXTERNAL_ACTIVITIES,
      emptyKey: 'EXTERNAL_ACTIVITIES',
    },
    GALLERY: {
      component: 'GalleryWidget',
      name: 'gallery',
      routeName: 'galleries',
      entryRouterName: 'GALLERY_ENTRY',
      icon: 'photo-video',
      colorId: 'galleries-light-default',
      colorHex: colors.GALLERY,
      emptyKey: 'GALLERY',
    },
    SLIDESHOW: {
      component: 'Slideshow',
      name: 'slideshow',
      routeName: 'galleries',
      icon: 'photo-video',
      colorId: 'galleries-light-default',
      colorHex: colors.GALLERY,
      emptyKey: 'SLIDESHOW',
    },
    JOURNEY_PLANNER: {
      component: 'JourneyPlannerWidget',
      icon: 'bus',
      colorId: 'journeyPlanner-dark-default',
      colorHex: colors.JOURNEY_PLANNER,
      emptyKey: 'JOURNEY_PLANNER',
    },
    MEAL_PLAN: {
      component: 'MealPlanWidget',
      name: 'mealPlan', // used for finding the name of the module passed from BE
      routeName: 'mealPlan', // used for module overview
      entryRouterName: 'MEAL_PLAN_ENTRY', // used for specific entry routing (name of router path for specific entry)
      icon: 'utensils',
      colorId: 'meals-light-default',
      colorHex: colors.MEAL_PLAN,
      emptyKey: 'MEAL_PLAN', // used to find asset names
    },
    MEETINGS: {
      component: 'MeetingsWidget',
      entryRouterName: 'MEETING_ENTRY',
      name: 'meetings',
      routeName: 'meetings',
      icon: 'comments-alt',
      colorId: 'meetings-light-default',
      colorHex: colors.MEETINGS,
      emptyKey: 'MEETINGS',
    },
    NEWS: {
      component: 'NewsWidget',
      entryRouterName: 'NEWS_ENTRY',
      name: 'news',
      routeName: 'news',
      icon: 'comment-alt-lines',
      colorId: 'news-light-default',
      colorHex: colors.NEWS,
      emptyKey: 'NEWS',
    },
    OUR_HOME: {
      component: 'OurHome',
      name: 'ourHome',
      routeName: 'ourHome',
      icon: 'house-user',
      colorId: 'about-light-default',
      colorHex: colors.OUR_HOME,
      emptyKey: 'OUR_HOME',
    },
    WAYFINDER: {
      component: 'Wayfinder',
      icon: 'id-card',
      colorId: 'schedule-light-default',
      colorHex: colors.WAYFINDER,
      emptyKey: 'WAYFINDER',
    },
    WEATHER: {
      component: 'WeatherWidget',
      icon: 'cloud',
      colorId: 'weather-light-default',
      colorHex: colors.WEATHER,
      emptyKey: 'WEATHER',
    },
    SIMPLE_WORK_SCHEDULE: {
      component: 'WorkScheduleWidget',
      name: 'simpleWorkSchedule',
      routeName: 'simpleWorkSchedule',
      icon: 'id-card',
      colorId: 'schedule-light-default',
      colorHex: colors.SIMPLE_WORK_SCHEDULE,
      emptyKey: 'SIMPLE_WORK_SCHEDULE',
    },
    WORK_SCHEDULE: {
      component: 'WorkScheduleWidget',
      name: 'workSchedule',
      routeName: 'workSchedule',
      icon: 'id-card',
      colorId: 'schedule-light-default',
      colorHex: colors.darkOcean[500],
      emptyKey: 'WORK_SCHEDULE',
    },
    LATER_WORK_SCHEDULE: {
      component: 'WorkScheduleWidget',
      name: 'laterWorkSchedule',
      routeName: 'laterWorkSchedule',
      icon: 'id-card',
      colorId: 'laterSchedule-light-default',
      colorHex: colors.darkOcean[500],
      emptyKey: 'LATER_WORK_SCHEDULE',
    },
  };
}
