import { apolloClient } from '../../utils/apollo';
import store from '@store';
import { GalleriesQuery } from '@graphql/queries/galleries.js';
import { GalleryEntryQuery } from '@graphql/queries/galleryEntry';
import { archiveAddScreenMutation } from '@graphql/mutations/archiveAddScreen.js';
import { archiveRemoveScreenMutation } from '@graphql/mutations/archiveRemoveScreen.js';

const state = {
  all: [],
  galleryEntry: {},
  categories: [],
  selectedCategory: 'AllCategoriesSelected',
};

const mutations = {
  SET_GALLERIES(state, galleries) {
    state.all = galleries;
  },
  SET_GALLERY_ENTRY(state, galleryEntry) {
    state.galleryEntry = galleryEntry;
  },
  SET_CATEGORIES(state, categories) {
    state.categories = categories;
  },
  SET_SELECTED_CATEGORY(state, category) {
    state.selectedCategory = category;
  },
};

const actions = {
  async getGalleries({ commit }) {
    const departmentId = store.getters['department/id'];

    if (!departmentId) {
      throw new Error('No department id provided');
    }

    await apolloClient
      .query({
        query: GalleriesQuery,
        variables: {
          filter: {
            departments: departmentId,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        commit('SET_GALLERIES', response.data.galleries);
      })
      .catch((error) => {
        console.log('Error getting galleries', error);
      });
  },
  async resetGalleryEntry({ commit }) {
    commit('SET_GALLERY_ENTRY', {});
  },
  async getGalleryEntry({ commit }, id) {
    await apolloClient
      .query({
        query: GalleryEntryQuery,
        variables: {
          id,
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        commit('SET_GALLERY_ENTRY', response.data.galleryEntry);
      })
      .catch((error) => {
        console.log('Error getting gallery', error);
      });
  },
  async archiveAddScreen({ commit }, { resourceId, profile, opt }) {
    const mutation =
      opt === 'in' ? archiveAddScreenMutation : archiveRemoveScreenMutation;

    await apolloClient
      .mutate({
        mutation: mutation,
        variables: {
          resourceId,
          profile,
        },
      })
      .then(async () => {
        await store.dispatch('profiles/setPinValidation', {
          validating: false,
          validPin: true,
        });
        store.dispatch('profiles/getMyArchive', {
          id: profile.profileId,
          type: profile.profileType,
        });
      })
      .catch((error) => {
        store.dispatch('profiles/setPinValidation', {
          validating: false,
          validPin: false,
        });
        throw new Error('Could not add to archive', error);
      });
  },
  setCategories({ commit }, categories) {
    commit('SET_CATEGORIES', categories);
  },
  setSelectedCategory({ commit }, category) {
    commit('SET_SELECTED_CATEGORY', category);
  },
};

const getters = {
  all: (state) => state.all,
  galleryEntry: (state) => state.galleryEntry,
  categories: (state) => state.categories,
  selectedCategory: (state) => state.selectedCategory,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
