import {
  format as formatDateFns,
  formatDistanceToNow as formatDistanceToNowDateFns,
  isWithinInterval as isWithinIntervalDateFns,
} from 'date-fns';
import { toDate } from 'date-fns-tz';
import { da, de, nb } from 'date-fns/locale';
import constants from '@utils/constants';
import store from '@store';

const locales = { da, de, no: nb };

export function format(date, formatStr, options) {
  const lang = store.getters['institution/locale'];
  return formatDateFns(date, formatStr, {
    ...options,
    locale: locales[lang],
  });
}

export function formatDistanceToNow(date, options) {
  const lang = store.getters['institution/locale'];
  return formatDistanceToNowDateFns(date, {
    ...options,
    locale: locales[lang],
  });
}

export function isWithinInterval(start, end) {
  const newDate = toDate(new Date(), {
    timeZone: constants.timezone,
  });

  return isWithinIntervalDateFns(newDate, {
    start: toDate(start),
    end: toDate(end),
  });
}
