<script setup>
import { computed } from 'vue';

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  isFocused: {
    type: Boolean,
    default: false,
  },
  route: {
    type: String,
    default: '',
  },
  isExternal: {
    type: Boolean,
    default: null,
  },
  icon: {
    type: [String, Array],
    default: null,
  },
  backgroundColor: {
    type: String,
    default: 'red',
  },
  pixelWidth: {
    type: Number,
    default: 104,
  },
});

const isFocusedStyle = computed(() => (props.isFocused ? 'shadow-lg' : null));
</script>

<template>
  <div>
    <!-- Container -->
    <div
      :class="[
        'transition-all',
        'duration-100',
        'ease-in-out',
        'bg-white',
        'rounded-xl',
        'border',
        'border-neutral-0',
        'p-3',
        isFocusedStyle,
      ]"
      :style="{
        transform: isFocusedStyle ? 'translateY(-15px)' : '',
        height: '104px',
        width: `${pixelWidth}px`,
      }"
    >
      <router-link
        :to="route"
        active-class="active"
        class="flex flex-col items-center justify-center"
      >
        <!-- Icon and background color -->
        <div
          :class="[
            'items-center',
            'justify-center',
            'rounded-full',
            'self-center',
            'flex',
            'flex-col',
            'text-center',
          ]"
          :style="{
            height: '56px',
            width: '56px',
            backgroundColor,
          }"
        >
          <!-- Use normal icon, unless it's an external module, in which case we use a custom img as icon -->
          <fa-icon
            v-if="!isExternal"
            style="color: white; height: 25px; width: 25px"
            :icon="icon"
          />
          <img
            v-else
            class="w-8"
            :src="`/img/external/${icon}.png`"
            :alt="icon"
          />
        </div>
        <!-- Module title -->
        <p class="pt-1 h200">
          {{ title }}
        </p>
      </router-link>
    </div>

    <!-- Focused bar -->
    <div v-show="isFocused" class="bg-black rounded-full" style="height: 6px" />
  </div>
</template>
