import * as Sentry from '@sentry/vue';
import router from '@router';

function getEnvironment() {
  const root = window.location.href;
  if (root.includes('localhost')) return 'development';
  if (root.includes('staging')) return 'staging';
  return 'production';
}

function scrubSensitiveData(event) {
  if (event?.extras?.pin) {
    event.extras.pin = 'REDACTED';
  }
  return event;
}

// When true init sentry with debug settings.
const SENTRY_DEV_DEBUG_ENABLED = false;

export function sentryInit(app) {
  const environment = getEnvironment();

  if (!SENTRY_DEV_DEBUG_ENABLED && environment === 'development') return;

  if (!import.meta.env.VITE_SENTRY_DSN && SENTRY_DEV_DEBUG_ENABLED) {
    console.error('Set DSN in .env.local to make sentry work');
  }

  Sentry.init({
    app,
    normalizeDepth: 6,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    beforeSend: scrubSensitiveData,
    integrations: [
      Sentry.browserTracingIntegration({ router, routeLabel: 'path' }),
    ],
    maxBreadcrumbs: 5,
    debug: false,
    release: import.meta.env.VITE_SENTRY_RELEASE || 'ibg-screen-vue@unknown',
    environment,
    tracePropagationTargets: [
      'localhost',
      'prod-ibg-screen.proreact.dk',
      'screen.staging.ibgmanager.dk',
      /^\//,
    ],
    tracesSampleRate: 0.01,
    attachProps: true,
    sendDefaultPii: true,
    sourcemaps: true,
  });
}
