<template>
  <div
    class="transition-opacity duration-500 screenDimmer absolute inset-0 z-100 bg-gray-900 pointer-events-none"
    :style="{ opacity: (100 - dimmer) / 105 }"
  />
</template>

<script setup>
import { computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const dimmer = computed(() => store.getters['general/dimmer']);

onBeforeMount(async () => {
  // Store sets dimmer to -1 if there is no localStorage value.
  if (dimmer.value === -1) {
    // Check application storage value
    const storageValue = localStorage.getItem('dimmer');
    // If the value is less than 0, set to 100 to prevent blacking out the screen.
    if (storageValue < 1) {
      await store.dispatch('general/setDimmer', 100);
    } else {
      await store.dispatch('general/setDimmer', storageValue);
    }
  }
});
</script>
