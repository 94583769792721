<script setup>
import { default as ButtonEl } from '@components/shared/Button.vue';

const emit = defineEmits(['archive-add', 'archive-remove']);

const props = defineProps({
  allowArchiveRemove: {
    type: Boolean,
    default: false,
  },
  mediaType: {
    type: String,
    default: '',
  },
});

function archiveAdd() {
  emit('archive-add');
}

function archiveRemove() {
  emit('archive-remove');
}
</script>

<template>
  <div class="flex items-center justify-center">
    <button-el
      v-if="!allowArchiveRemove"
      :text="`media.addToMy.${mediaType}`"
      icon="cloud-download"
      background-color="success"
      class="shadow-xsm mb-10"
      @click="archiveAdd()"
    />

    <button-el
      v-else
      :text="`media.removeFromMy.${mediaType}`"
      icon="trash-alt"
      background-color="error"
      class="shadow-xsm mb-10"
      @click="archiveRemove()"
    />
  </div>
</template>
