import gql from 'graphql-tag';

export const cancelSlottedBookingScreenMutation = gql`
  mutation booking(
    $profile: ScreenProfile!
    $bookingSlot: BookingResourceBookingSlot!
  ) {
    booking {
      cancelSlottedBookingScreen(profile: $profile, bookingSlot: $bookingSlot) {
        id
        message
        success
      }
    }
  }
`;
