<script setup>
import CardBasic from '@components/cards/Basic.vue';

const emit = defineEmits(['resource-selected']);

defineProps({
  items: {
    type: Array,
    default() {
      return [];
    },
  },
  activeItemIndex: {
    type: Number,
    default: -1,
  },
});

function getItemTitle(item) {
  return item.name || item.title;
}
</script>

<template>
  <div class="grid grid-cols-3 p-10 gap-14">
    <CardBasic
      v-for="(item, index) in items"
      :key="index"
      :title="getItemTitle(item)"
      :image-url="item.coverImage.source"
      :active="activeItemIndex === index"
      class="shadow-xsm"
      @click="$emit('resource-selected', item)"
    />
  </div>
</template>
