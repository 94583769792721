<script setup>
import IconButton from '@components/shared/IconButton.vue';
import Slider from './Slider.vue';
import { ref, onMounted, onBeforeUnmount } from 'vue';

const showSlider = ref(false);
const timeoutRef = ref(true);

const dimmerClicked = () => {
  showSlider.value = !showSlider.value;
  // TODO: https://proreact.atlassian.net/browse/IBG-6093
  // $matomo.trackEvent('Dimmer', 'Clicked', 'ScreenDimmer');
  // window._paq.push(['Dimmer', 'Clicked', ScreenDimmer]);
  // window._paq.push(['TrackEvent', 'Clicked', 'ScreenDimmer']);
  handleSliderVisibility();
};

// Sets a timer to close slider if its open.
const handleSliderVisibility = () => {
  // Set a timer if not set.
  if (showSlider.value && !timeoutRef.value) {
    timeoutRef.value = setTimeout(() => {
      showSlider.value = false;
      timeoutRef.value = null;
    }, 10000);
    // If slider is hidden clear the timeout.
  } else if (!showSlider.value && timeoutRef.value) {
    clearTimeout(timeoutRef.value);
    timeoutRef.value = null;
  }
};

onMounted(() => handleSliderVisibility());

// Clear the timeout when the component is destroyed and hide the slider.
onBeforeUnmount(() => {
  showSlider.value = false;
  if (timeoutRef.value) {
    clearTimeout(timeoutRef.value);
  }
});
</script>

<template>
  <div class="flex flex-col">
    <div
      v-if="showSlider"
      class="flex py-[25px] bg-neutral-300 rounded-full shadow-lg absolute justify-center"
      style="width: 76px; height: 300px; bottom: 98px"
    >
      <Slider />
    </div>
  </div>
  <IconButton :icon="['far', 'sun-bright']" @click="dimmerClicked" />
</template>
