import { ApolloClient, InMemoryCache, from } from '@apollo/client/core';
import fetch from 'unfetch';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';

const httpLink = createHttpLink({ uri: import.meta.env.VITE_API_URL, fetch });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('api_token');

  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const retryIf = (error) => {
  const doNotRetryCodes = [500, 400];
  return !!error && !doNotRetryCodes.includes(error.statusCode);
};

const retryLink = new RetryLink({
  delay: {
    initial: 400,
    max: 5000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf,
  },
});

const errorLink = onError(({ _, networkError }) => {
  if (networkError && networkError?.statusCode === 401) {
    auth.authenticate();
  }
});

export const apolloClient = new ApolloClient({
  link: from([authLink, retryLink, errorLink, httpLink]),
  cache: new InMemoryCache(),
});
