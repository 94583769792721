<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import colors from '@utils/colors.js';

const route = useRoute();

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  imageUrl: {
    type: String,
    default: '',
  },
  active: {
    type: Boolean,
    default: false,
  },
});

const moduleColor = computed(() => {
  return route.meta.color;
});

const moduleColorHex = computed(() => {
  return colors[moduleColor.value];
});

const backgroundColor = computed(() => {
  return props.active ? `bg-${moduleColor.value}` : '';
});
</script>

<template>
  <div
    class="card-basic flex flex-col items-center justify-center relative p-4"
    :class="backgroundColor"
  >
    <span
      class="content bold text-center"
      :style="{ color: props.active ? 'white' : moduleColorHex }"
    >
      {{ $t(props.title) }}
    </span>

    <img class="mt-6" v-if="props.imageUrl" :src="props.imageUrl" />

    <span
      v-if="props.active"
      class="active-icon rounded-full bg-success flex items-center justify-center border-2 border-white absolute"
    >
      <fa-icon class="text-white text-3xl" :icon="['fal', 'check']" />
    </span>
  </div>
</template>

<style lang="scss">
.card-basic {
  border-radius: 12px;
  width: 300px;
  height: 300px;

  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
  }

  .active-icon {
    width: 90px;
    height: 90px;
    right: 20px;
    bottom: 20px;
  }
}
</style>
