import gql from 'graphql-tag';

export const meetingSignupScreenMutation = gql`
  mutation meeting(
    $subscription: MeetingSubscription!
    $profile: ScreenProfile!
  ) {
    meeting {
      signupScreen(profile: $profile, subscription: $subscription) {
        id
        message
        success
      }
    }
  }
`;
