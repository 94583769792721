import gql from 'graphql-tag';

export const scheduleQuery = gql`
query schedule ($dateFrom: Date!, $dateTo: Date!, $filter: Filter!, $timezone: TimeZone!) {
  schedule {
    shifts (dateFrom: $dateFrom, dateTo: $dateTo, filter: $filter, timezone: $timezone) {
      cancelled
      employee {
          displayImage
          displayName
          showImage
          id
          shift {
              end
              id
              start
          }
      }
      end
      id
      nightShift
      start
      unavailable {
        description
        end
        start
      }
    }
  }
}`