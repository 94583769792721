<script setup>
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import colors from '@utils/colors.js';

const route = useRoute();

const props = defineProps({
  imageUrl: {
    type: String,
    default: '',
  },
  gradient: {
    type: Boolean,
    default: false,
  },
});

const moduleColor = computed(() => {
  return route?.meta?.color;
});

const wrapperClass = computed(() => {
  return `bg-${moduleColor.value}`;
});

onMounted(() => {
  const selector = document.querySelector('.route-wrapper');
  selector.scrollTop = 400;
});
</script>

<template>
  <div class="coverView w-full h-full" :class="wrapperClass">
    <div
      :style="{
        backgroundImage: `url(${props.imageUrl})`,
        backgroundColor: !props.imageUrl.length
          ? colors[moduleColor.value]
          : 'none',
        height: '800px',
        opacity: !props.imageUrl.length ? '0.9' : '1',
      }"
      class="backgroundWrap z-10 bg-center bg-no-repeat bg-cover bg-inherit fixed w-full animate__animated animate__fadeIn animate__delay-1s animate__fast"
    />

    <div
      class="viewContent w-full animate__animated animate__slideInUp animate__fast z-20"
      :style="{ marginTop: '40vh' }"
    >
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.animationStarting .viewContent {
  margin-top: 100vh;
}
.backgroundWrap {
  opacity: 1;
}
.viewContent {
  color: #323232;
  min-height: 1500px;
  position: absolute;

  > :first-child {
    border-radius: 25px 25px 0 0;
    background: white;
    min-height: 1500px;
  }
}
</style>
