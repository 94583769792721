<script setup>
import { computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import i18n from '@i18n';
import { default as ButtonEl } from '@components/shared/Button.vue';
import { get, capitalize } from 'lodash';
import CoverView from '@views/shared/CoverView.vue';
import PinFlow from '@utils/pinFlow';
import PeopleBlock from '@components/shared/blocks/PeopleBlock.vue';
import DateTimeBlock from '@components/shared/blocks/DateTimeBlock.vue';
import DescriptionBlock from '@components/shared/blocks/DescriptionBlock.vue';
import InstitutionBlock from '@components/shared/blocks/InstitutionBlock.vue';

const store = useStore();
const route = useRoute();

const allBookingResources = computed(
  () => store.getters['bookingResources/all']
);
const resource = computed(
  () => store.getters['bookingResources/selectedBookingResource']
);

const pinValidation = computed(() => store.getters['profiles/pinValidation']);
const opt = computed(() => store.getters['bookingResources/opt']);

const moduleColor = computed(() => {
  return route?.meta?.color;
});

const imageUrl = computed(() => {
  return get(resource.value, 'coverImage.source', '/img/placeholder.png');
});

onMounted(() => {
  window._paq.push(['trackEvent', 'BookingEntry', 'Viewed']);
});

watch(allBookingResources, (resources) => {
  const resource = resources.find(
    (resource) => resource.id === resource?.value?.id
  );

  if (!resource) return;
  store.dispatch('bookingResources/setSelectedBookingResource', resource);
});

watch(pinValidation, (pinData) => {
  if (!pinData.validPin) return;

  if (resource.value.slotted) {
    bookSlot();
  } else {
    startNewBooking();
  }
});

function showProfiles(profileType, index) {
  store.dispatch('general/setActiveOverlay', {
    name: 'participants-list',
    data: {
      title: `global.${profileType}`,
      profiles: resource.value.bookings[index].participants,
    },
  });
}

async function startNewBooking() {
  store.dispatch('bookingResources/setNewBooking', {
    resourceId: resource.value.id,
  });

  await store.dispatch('general/setActiveOverlay', {
    name: 'new-booking',
    data: {
      title: `${capitalize(i18n.global.t('booking.book'))} ${resource.value.title}`,
    },
  });
}

async function startNewSlottedBooking() {
  await store.dispatch('general/setActiveOverlay', {
    name: 'new-slotted-booking',
    data: {
      title: `${capitalize(i18n.global.t('booking.book'))} ${resource.value.title}`,
    },
  });
}

async function newBooking() {
  if (resource.value.slotted) {
    await store.dispatch('bookingResources/setSelectedSlottedWeek');
    startNewSlottedBooking();
    return;
  }

  const pinFlow = new PinFlow({
    entity: 'booking',
    type: 'create',
    title: resource.value.title,
    sharedDepartments: resource.value.departments,
  });

  switch (resource.value.authentication) {
    case 'NONE':
      startNewBooking();
      break;
    case 'PASSWORD':
      pinFlow.startCreateFlow('booking.toBook');
      break;
    case 'EMPLOYEE_ONLY':
      pinFlow.startCreateFlow('booking.toBook', true);
      break;
  }
}

async function bookSlot() {
  if (opt.value === 'in') {
    await store.dispatch('bookingResources/createNewSlottedBooking');
  } else {
    await store.dispatch('bookingResources/cancelSlottedBooking');
  }

  startNewSlottedBooking();
}
</script>

<template>
  <div class="booking">
    <cover-view
      v-if="resource.id"
      :image-url="imageUrl"
      :gradient="true"
      :module-color="moduleColor"
      :margin-top="60"
    >
      <div class="bookingContent pt-20">
        <div class="largeListItemHeader pt-12 mb-12">
          {{ resource.title }}
        </div>

        <description-block :description="resource.description" />

        <institution-block />

        <div
          v-for="(booking, index) in resource.bookings"
          :key="index"
          class="relative content mb-14 w-full"
        >
          <date-time-block :start="booking.start" :end="booking.end" />

          <description-block :description="booking.description" />

          <people-block
            v-if="booking.participants.length"
            :module-color="moduleColor"
            :profiles="booking.participants"
            @show-people="showProfiles('participants', index)"
          />
        </div>

        <div class="newBooking flex w-full sticky justify-end">
          <button-el
            text="booking.newBooking"
            icon="plus"
            background-color="success"
            text-color="white"
            class="mr-5 shadow-xsm"
            @click="newBooking"
          />
        </div>
      </div>
    </cover-view>
  </div>
</template>

<style lang="scss">
.booking {
  .bookingContent {
    &.participants {
      margin-top: 700px;
    }

    > *:not(.mediaIcons) {
      padding: 0 100px;
    }
  }

  .newBooking {
    bottom: 150px;
    padding-right: 80px;
  }
}
</style>
