import { isFuture } from 'date-fns';
import { formatTime } from '@utils/helpers.js';
import store from '@store';

export function getAllResourceBookings(resources) {
  if (!resources) return [];
  let coverImage;

  return resources
    .filter((resource) => resource.bookings.length)
    .flatMap((resource) => {
      coverImage = resource.coverImage; // Add the resource coverImage to the bookings
      return resource.bookings;
    })
    .filter((booking) => isFuture(new Date(booking.end)))
    .map((booking) => {
      return {
        ...booking,
        coverImage,
        title: booking.description,
        startDate: booking.start,
        endDate: booking.end,
        meta: {
          icon: 'clock',
          text: formatTime(new Date(booking.start)),
        },
      };
    })
    .toSorted((a, b) => new Date(a.start) - new Date(b.start));
}
export function getBookingById(id) {
  const allBookingResources = store.getters['bookingResources/all'];
  return allBookingResources
    .flatMap((resource) =>
      resource.bookings.map((booking) => ({
        ...booking,
        coverImage: resource.coverImage,
      }))
    )
    .find((booking) => booking.id === id);
}
