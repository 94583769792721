<script setup>
import { useStore } from 'vuex';
import { computed, onBeforeMount, ref } from 'vue';
import { getModuleData } from '@utils/modules';
import ModuleBaseView from '@components/moduleBaseView/ModuleBaseView.vue';

const store = useStore();
const isLoading = ref(true);
const moduleData = computed(() => store.getters['news/all']);

const { colorHex, icon, emptyKey, routeName, entryRouterName } =
  getModuleData().NEWS;

onBeforeMount(async () => {
  await store.dispatch('news/getNews', { useCache: true });
  isLoading.value = false;
});
</script>

<template>
  <ModuleBaseView
    v-if="!isLoading"
    :moduleName="emptyKey"
    :moduleData="moduleData"
    :colorHex="colorHex"
    :icon="icon"
    :emptyKey="emptyKey"
    :routeName="routeName"
    :entryRouterName="entryRouterName"
    :shouldShowInfoTag="false"
    :shouldShowDate="false"
    :shouldShowSectionDivider="false"
  />
</template>
