import { apolloClient } from '../../utils/apollo';
import store from '@store';
import { isAfter, isBefore, endOfDay, subDays, addDays } from 'date-fns';
import { format } from '@utils/date-fns';
import { toDate } from 'date-fns-tz';
import { get } from 'lodash';
import { scheduleQuery } from '@graphql/queries/schedule.js';
import { shiftUpdateMutation } from '@graphql/mutations/shiftUpdate.js';
import { shiftDeleteMutation } from '@graphql/mutations/shiftDelete.js';
import * as Sentry from '@sentry/vue';

const state = {
  all: [],
  workingNow: [],
  comingLater: [],
  isLoading: false,
  type: '',
  selectedShift: {},
  editAction: '',
  replaceWithEmployeeId: null,
};

const mutations = {
  SET_WORKSCHEDULE_ALL(state, schedule) {
    state.all = schedule;
  },
  SET_WORKSCHEDULE_NOW(state, schedule) {
    state.workingNow = schedule;
  },
  SET_WORKSCHEDULE_LATER(state, schedule) {
    state.comingLater = schedule;
  },
  SET_ISLOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_WORKSCHEDULE_TYPE(state, type) {
    state.type = type;
  },
  SET_SELECTED_SHIFT(state, shift) {
    state.selectedShift = shift;
  },
  SET_REPLACE_WITH_EMPLOYEE_ID(state, id) {
    state.replaceWithEmployeeId = id;
  },
  SET_EDIT_ACTION(state, action) {
    state.editAction = action;
  },
};

const getFilteredShifts = (schedule, filter) => {
  return schedule
    .filter((shift) => {
      if (filter === 'later') {
        if (
          isAfter(new Date(shift.start), new Date()) &&
          isBefore(new Date(shift.start), endOfDay(new Date()))
        )
          return true;
        return false;
      }

      if (isAfter(new Date(), new Date(shift.end))) return false;
      if (isAfter(new Date(shift.start), new Date())) return false;
      return true;
    })
    .map((shift) => {
      return {
        ...shift,
        start: toDate(shift.start, { timeZone: 'Europe/Copenhagen' }),
        end: toDate(shift.end, { timeZone: 'Europe/Copenhagen' }),
      };
    });
};

const getRange = () => {
  const currentDepartmentId = store.getters['department/id'];
  const departmentSettings = store.getters['department/settings'];
  const institutionSettings = store.getters['institution/settings'];

  const matchingDepartment = institutionSettings.shift?.find(
    ({ departmentId }) => departmentId == currentDepartmentId
  );
  const now = new Date();

  // Subtract 1 because the core admin setting is not 0 based but 1 should mean 1 days forward.
  const daysForward =
    get(
      matchingDepartment,
      'daysForward',
      departmentSettings.shift.daysForward
    ) - 1;

  return {
    from: subDays(now, daysForward),
    to: addDays(now, daysForward),
  };
};

const actions = {
  async getWorkSchedules({ commit }) {
    const range = getRange();

    const departmentId = store.getters['department/id'];
    await apolloClient
      .query({
        query: scheduleQuery,
        variables: {
          filter: {
            departments: departmentId,
          },
          dateFrom: format(range.from, 'yyyy-MM-dd'),
          dateTo: format(range.to, 'yyyy-MM-dd'),
          timezone: 'Europe/Copenhagen',
        },
        fetchPolicy: 'no-cache',
      })
      .then((response) => {
        const shifts = get(response, 'data.schedule.shifts', []);

        commit('SET_WORKSCHEDULE_ALL', shifts);
        commit('SET_WORKSCHEDULE_LATER', getFilteredShifts(shifts, 'later'));
        commit('SET_WORKSCHEDULE_NOW', getFilteredShifts(shifts, 'now'));

        if (state?.selectedShift?.id) {
          const updatedShift = shifts.find((shift) => {
            return shift?.id === state?.selectedShift?.id;
          });
          commit('SET_SELECTED_SHIFT', updatedShift);
        }
      })
      .catch((error) => {
        Sentry.captureException(error, {
          extra: {
            state,
            departmentId,
          },
        });
      });
  },
  setType({ commit }, type) {
    if (!type) {
      const modules =
        store.getters['department/settings'].screenConfig?.modules?.map(
          (module) => module.type
        ) || [];

      const wsType = modules.includes('SIMPLE_WORK_SCHEDULE')
        ? 'SIMPLE_WORK_SCHEDULE'
        : 'WORK_SCHEDULE';

      commit('SET_WORKSCHEDULE_TYPE', wsType);
    }

    commit('SET_WORKSCHEDULE_TYPE', type);
  },
  async shiftUpdate({ commit }, shiftData) {
    await apolloClient
      .mutate({
        mutation: shiftUpdateMutation,
        variables: {
          shift: {
            id: state.selectedShift.id,
            employeeId:
              state.replaceWithEmployeeId || state.selectedShift.employee.id,
            cancelled: shiftData.cancel,
          },
        },
      })
      .then(async () => {
        store.dispatch('workSchedule/getWorkSchedules', shiftData.dates);

        state.selectedShift = state.all.find(
          (shift) => shift.id === state.selectedShift.id
        );

        await store.dispatch('profiles/setPinValidation', {
          validating: false,
          validPin: undefined,
        });
      })
      .catch((error) => {
        store.dispatch('profiles/setPinValidation', {
          validating: false,
          validPin: undefined,
        });
        throw new Error('Error updating shift', error);
      });
  },
  async shiftDelete({ commit }, shiftData) {
    await apolloClient
      .mutate({
        mutation: shiftDeleteMutation,
        variables: {
          id: shiftData.shiftId,
        },
      })
      .then(async () => {
        store.dispatch('workSchedule/getWorkSchedules', shiftData.dates);
        await store.dispatch('profiles/setPinValidation', {
          validating: false,
          validPin: false,
        });
      })
      .catch((error) => {
        store.dispatch('profiles/setPinValidation', {
          validating: false,
          validPin: false,
        });
        throw new Error('Error deleting shift', error);
      });
  },
  setSelectedShift({ commit }, shift) {
    commit('SET_SELECTED_SHIFT', shift);
  },
  setReplaceWithEmployeeId({ commit }, id) {
    commit('SET_REPLACE_WITH_EMPLOYEE_ID', id);
  },
  setEditAction({ commit }, action) {
    commit('SET_EDIT_ACTION', action);
  },
};

const getters = {
  all: (state) => state.all,
  workingNow: (state) => state.workingNow,
  comingLater: (state) => state.comingLater,
  isLoading: (state) => state.isLoading,
  type: (state) => state.type,
  selectedShift: (state) => state.selectedShift,
  editAction: (state) => state.editAction,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
