import gql from 'graphql-tag';

export const bookingResourcesQuery = gql`
  query bookingResources($filter: Filter!, $timezone: TimeZone!) {
    bookingResources(filter: $filter, timezone: $timezone) {
      id
      title
      description
      authentication
      slotted
      bookings {
        end
        id
        description
        start
        participants {
          ... on Employee {
            displayName
            displayImage
            showImage
            id
            __typename
          }
          ... on Resident {
            displayName
            displayImage
            showImage
            id
            __typename
          }
          __typename
        }
        __typename
      }
      coverImage {
        blurhash
        description
        id
        source
        __typename
      }
      __typename
    }
  }
`;
