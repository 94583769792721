<script setup>
import { computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { getModuleData } from '@utils/modules';
import { useRouter } from 'vue-router';
import ModuleBaseView from '@components/moduleBaseView/ModuleBaseView.vue';
import ResourceSelector from '@components/shared/ResourceSelector.vue';

const store = useStore();
const router = useRouter();

onBeforeMount(() => {
  // Set activeViewType to prevent viewMode to be toggled, as that indicates that were on either list/grid view
  // set activeContentView to new-booking to show booking ressources over list/grid view
  store.dispatch('general/setActiveViewType', '');
  store.dispatch('general/setActiveContentView', { name: 'new-booking' });
  store.dispatch('bookingResources/getBookingResources', { useCache: true });
});

const bookingResources = computed(() => store.getters['bookingResources/all']);
const bookings = computed(
  () => store.getters['bookingResources/resourceBookings']
);

const activeContentView = computed(
  () => store.getters['general/activeContentView']
);

const resourceSelected = (resource) => {
  store.dispatch('bookingResources/setSelectedBookingResource', resource);
  router.push(`/bookings/resource/${resource.id}`);
};

const bookingSelected = (resource) => {
  store.dispatch('bookingResources/setSelectedBookingEntry', resource);
  router.push(`/bookings/booking/${resource.id}`);
};

const { colorHex, icon, emptyKey, routeName, entryRouterName } =
  getModuleData().BOOKING;
</script>

<template>
  <ResourceSelector
    v-if="activeContentView?.name === 'new-booking'"
    :items="bookingResources"
    @resource-selected="resourceSelected($event)"
  />
  <ModuleBaseView
    v-else
    :moduleName="emptyKey"
    :moduleData="bookings"
    :colorHex="colorHex"
    :icon="icon"
    :emptyKey="emptyKey"
    :routeName="routeName"
    :entryRouterName="entryRouterName"
    @entryItemClicked="bookingSelected($event)"
  />
</template>
