<script setup>
import { ref, computed, defineAsyncComponent, onMounted } from 'vue';
import { useStore } from 'vuex';
import overlays from '@utils/overlays';

const store = useStore();
const emit = defineEmits(['close-overlay']);

const COMPONENT_MAP = {
  'profile-select': defineAsyncComponent(
    () => import('@views/shared/ProfileSelect.vue')
  ),
  'participants-list': defineAsyncComponent(
    () => import('@views/overlays/ParticipantsList.vue')
  ),
  'enter-pin': defineAsyncComponent(
    () => import('@views/overlays/EnterPin.vue')
  ),
  agenda: defineAsyncComponent(() => import('@views/overlays/Agenda.vue')),
  'work-schedule-entry': defineAsyncComponent(
    () => import('@views/overlays/WorkScheduleEntry.vue')
  ),
  suggestions: defineAsyncComponent(
    () => import('@views/overlays/Suggestions.vue')
  ),
  'write-suggestion': defineAsyncComponent(
    () => import('@views/overlays/WriteSuggestion.vue')
  ),
  'new-booking': defineAsyncComponent(
    () => import('@views/overlays/NewBooking.vue')
  ),
  'new-slotted-booking': defineAsyncComponent(
    () => import('@views/overlays/NewSlottedBooking.vue')
  ),
  'new-booking-description': defineAsyncComponent(
    () => import('@views/overlays/NewBookingDescription.vue')
  ),
  'new-booking-participants': defineAsyncComponent(
    () => import('@views/overlays/NewBookingParticipants.vue')
  ),
  'replace-shift-employee': defineAsyncComponent(
    () => import('@views/overlays/ReplaceShiftEmployee.vue')
  ),
  'replace-shift-confirmation': defineAsyncComponent(
    () => import('@views/overlays/ReplaceShiftConfirmation.vue')
  ),
};

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
});

const showBackdrop = ref(false);

const activeOverlay = computed(() => store.getters['general/activeOverlay']);

const overlay = computed(() => {
  return overlays.find((overlay) => overlay.name === activeOverlay.value.name);
});

const departmentSettings = computed(() => store.getters['department/settings']);

const hasDepartmentSwitcher = computed(() => {
  return departmentSettings.value?.screenConfig?.departmentSwitcher || false;
});

function close(force) {
  emit('close-overlay', force);
}
</script>

<template>
  <transition
    enter-active-class="animate__animated animate__slideInUp"
    leave-active-class="animate__animated animate__slideOutDown"
    :duration="{ enter: showBackdrop ? 1000 : 0, leave: 800 }"
    @after-enter="showBackdrop = true"
  >
    <div
      v-if="show"
      class="overlay overflow-y-scroll flex flex-col w-full fixed left-0 fixed top-0 z-30"
      :class="[hasDepartmentSwitcher ? 'bottom-[300px]' : 'bottom-[250px]']"
    >
      <transition
        enter-active-class="animate__animated animate__slideInUp"
        leave-active-class="animate__animated animate__slideOutDown"
        :duration="1000"
      >
        <div
          v-if="showBackdrop"
          class="backdrop bg-white shadow-top pb-12 h-fit min-h-[1300px] mt-[500px]"
        >
          <div
            v-if="overlay.canClose"
            class="flex justify-between my-20 mx-20 items-center"
          >
            <div class="largeListItemHeader">
              {{ $t(overlay.title || '') }}
            </div>

            <fa-icon
              :icon="['fal', 'times']"
              class="text-5xl font-bold"
              @click="close(true, true)"
            />
          </div>

          <component
            :is="COMPONENT_MAP[overlay.name]"
            class="min-h-full mb-32 bg-white"
            @close-overlay="close"
          />
        </div>
      </transition>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.overlay {
  .backdrop {
    border-radius: 25px 25px 0 0;
  }
}
</style>
