const state = {
  selectedDepartmentId: {}
};

const mutations = {
  SET_SELECTED_DEPARTMENTID(state, id) {
    state.selectedDepartmentId = id;
  }
};

const actions = {
  setSelectedDepartmentId({ commit }, id) {
    commit('SET_SELECTED_DEPARTMENTID', id);
  }
};

const getters = {
  selectedDepartmentId: state => state.selectedDepartmentId
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}