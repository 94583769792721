import gql from 'graphql-tag';

export const GalleriesQuery = gql`
query galleries ($filter: Filter!) {
    galleries (filter: $filter) {
        id
        title
        showOnFrontpage
        category {
            id
            title
            description
        }
        coverImage {
            id
            description
            blurhash
            source
        }
        pictures {
            id
            description
            blurhash
            source
            favorited
            inMyArchive
        }
        videos {
            id
            provider
            url
            description
            blurhash
            favorited
            thumbnail
        }
        media {
            ... on GalleryPicture {
                id
                source
            }
            ... on GalleryVideo {
                id
                url
                thumbnail
            }
        }
        settings {
            dreambrokerUrl
        }
    }
}`