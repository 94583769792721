import gql from "graphql-tag";

export const bookResourceScreenMutation = gql`
mutation booking ($participants: [ProfileInput!]!, $booking: BookingResourceBooking!) {
  booking {
    bookResourceScreen (participants: $participants, booking: $booking) {
        id
        message
        success
    }
  }
}`

