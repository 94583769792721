import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSunBright,
  faTableCellsLarge,
  faTableCells,
  faGrid,
  faArrowLeft,
  faBan,
  faBell,
  faAngleLeft,
  faAngleRight,
  faList,
  faTh,
  faClock,
  faImage,
  faVolume,
  faSoup,
  faAlignLeft,
  faAlarmClock,
  faUserFriends,
  faHouse,
  faHotel,
  faCheck,
  faMinusCircle,
  faImages,
  faCloudDownload,
  faPlayCircle,
  faChevronLeft,
  faChevronRight,
  faTimes,
  faCalendarPlus,
  faFilm,
  faCaretRight,
  faPlay,
  faPause,
  faUndoAlt,
  faSquare,
  faFileAlt,
  faPhotoVideo,
  faStreetView,
  faTrashAlt,
  faBirthdayCake,
  faHouseUser,
  faBackspace,
  faPlus,
  faArrowRight,
  faTag,
  faRepeat,
  faLightbulb,
  faSpinner,
  faPenAlt,
  faIcons,
  faCalendar,
  faEdit,
  faCoins,
  faUserSlash,
  faMapMarkerAlt,
  faExchangeAlt,
  faTrash,
  faUndo,
  faExchange,
  faMoonStars,
  faMobile,
  faPhone,
} from '@fortawesome/pro-light-svg-icons';

// LIGHT

library.add(
  faSunBright,
  faTableCellsLarge,
  faTableCells,
  faGrid,
  faArrowLeft,
  faBan,
  faBell,
  faAngleLeft,
  faAngleRight,
  faList,
  faTh,
  faClock,
  faImage,
  faVolume,
  faSoup,
  faAlignLeft,
  faAlarmClock,
  faUserFriends,
  faHouse,
  faHotel,
  faCheck,
  faMinusCircle,
  faImages,
  faCloudDownload,
  faPlayCircle,
  faChevronLeft,
  faChevronRight,
  faTimes,
  faCalendarPlus,
  faFilm,
  faCaretRight,
  faPlay,
  faPause,
  faUndoAlt,
  faSquare,
  faFileAlt,
  faPhotoVideo,
  faStreetView,
  faTrashAlt,
  faBirthdayCake,
  faHouseUser,
  faBackspace,
  faPlus,
  faArrowRight,
  faTag,
  faRepeat,
  faLightbulb,
  faSpinner,
  faPenAlt,
  faIcons,
  faCalendar,
  faEdit,
  faCoins,
  faUserSlash,
  faMapMarkerAlt,
  faExchangeAlt,
  faTrash,
  faUndo,
  faExchange,
  faMoonStars,
  faMobile,
  faPhone
);
