/**
 * Handles the logic of setting idle to the correct value.
 * 
 * If isPaused has been set to true, the isIdle updates will not be set to true, in order to 
 * prevent screen to go idle, when user is playing slideshows.
 * 
 * If isPaused is false, isIdle updates are allowed and the status can be set to true, to reset the screen.
 */
const state = {
  isPaused: false,
  isIdle: false,
};

const mutations = {
  SET_IS_PAUSED(state, isPaused) {
    state.isPaused = isPaused
  },
  SET_IDLE_STATUS(state, isIdle) {
    if (state.isPaused) {
      state.isIdle = false;
    } else {
      state.isIdle = isIdle
    }
  },
};

const getters = {
  isIdle: state => state.isIdle,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions: {},
  getters
};