<script setup>
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

function reload() {
  if (route.path !== '/') return;
  router.go(0);
}
</script>

<template>
  <router-link to="/" class="bg-transparent">
    <img
      src="/svgs/ibg_logo.svg"
      class="p-2 border-2 border-neutral-400 rounded-full bg-white shadow-lg"
      style="height: 98px; width: 98px"
      @click="reload()"
    />
  </router-link>
</template>
