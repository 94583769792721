<script setup>
import colors from '@utils/colors.js';
import { getAATextColor } from '@utils/helpers.js';
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();

const props = defineProps({
  text: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: '',
  },
  backgroundColor: {
    type: String,
    default: '',
  },
  textColor: {
    type: String,
    default: '',
  },
  padding: {
    type: Boolean,
    default: true,
  },
  shadow: {
    type: Boolean,
    default: false,
  },
  isSave: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const isSaving = computed(() => {
  return store.getters['general/isSaving'];
});

const backgroundColorHex = computed(() => {
  if (!props.backgroundColor) return '';
  if (props.backgroundColor.charAt(0) === '#') return props.backgroundColor;
  return colors[props.backgroundColor];
});

const textColorHex = computed(() => {
  if (props.textColor) return colors[props.textColor];

  if (!props.backgroundColor) return getAATextColor(colors.white);
  if (props.textColor.charAt(0) === '#') return props.textColor;
  return getAATextColor(backgroundColorHex.value);
});
</script>

<template>
  <div
    class="flex justify-center items-center text-base rounded-lg h-18"
    :class="{
      border: backgroundColorHex === '#fff' || backgroundColorHex === '#ffffff',
      'px-10 py-6': padding,
      'shadow-xsm': shadow,
    }"
    :style="[
      {
        backgroundColor: backgroundColorHex,
        color: textColorHex,
        opacity: props.disabled ? 0.6 : 1,
      },
    ]"
  >
    <span v-if="icon">
      <fa-icon
        v-if="isSave && isSaving"
        class="mr-3 fa-spin text-3xl"
        :icon="['fal', 'spinner']"
      />
      <fa-icon
        v-else
        :class="{ 'mr-3 text-3xl': text.length }"
        :icon="['fal', `${icon}`]"
      />
    </span>
    <span class="text-3xl">{{ $t(text) }}</span>
  </div>
</template>
