import gql from 'graphql-tag';

export const shiftUpdateMutation = gql`
  mutation shiftUpdate ($shift: ScheduleShiftInput!) {
    shift {
      update(shift: $shift) {
        id
        success
        message
    }
  }
}`