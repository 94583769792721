import { apolloClient } from '@utils/apollo';
import { ttsQuery } from '@graphql/queries/tts.js';
import { ref } from 'vue';

const data = ref();
const error = ref(null);

export default async function getTTS(text) {
  await apolloClient
    .query({
      query: ttsQuery,
      variables: {
        speakingRate: 'MEDIUM',
        text,
      },
      fetchPolicy: 'no-cache',
    })
    .then((response) => {
      data.value = response.data.tts;
    })
    .catch((error) => {
      error.value = error;
    });

  return { data, error };
}
