// https://www.divotion.com/blog/lazy-load-images-with-the-intersection-obersver-api
export default {
  mounted(el) {
    const options = {
      root: document.getElementsByClassName('route-wrapper')[0],
      rootMargin: '300px',
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const lazyImage = entry.target;
          if (lazyImage.dataset.src) {
            lazyImage.src = lazyImage.dataset.src;
            imageObserver.unobserve(el);
          }
        }
      });
    };

    const imageObserver = new IntersectionObserver(callback, options);

    imageObserver.observe(el);
  },
};
