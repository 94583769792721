<script setup>
import { ref, onMounted, defineAsyncComponent } from 'vue';
import CarouselControls from '@components/shared/CarouselControls.vue';
import ArchiveControls from '@components/shared/ArchiveControls.vue';

const VuePdfEmbed = defineAsyncComponent(() => import('vue-pdf-embed'));

const emit = defineEmits(['archive-add', 'archive-remove', 'close-preview']);
const currentDocumentIndex = ref();

const props = defineProps({
  documents: {
    type: Array,
    default: () => [],
  },
  selectedItemIndex: {
    type: Number,
    default: 0,
  },
  allowArchiveRemove: {
    type: Boolean,
    default: false,
  },
});

onMounted(() => {
  currentDocumentIndex.value = props.selectedItemIndex;
});

function goNext() {
  const nextIndex = currentDocumentIndex.value + 1;

  currentDocumentIndex.value =
    nextIndex >= props.documents.length ? 0 : nextIndex;
}

function goPrevious() {
  const previousIndex = currentDocumentIndex.value - 1;

  currentDocumentIndex.value =
    previousIndex < 0 ? props.documents.length - 1 : previousIndex;
}

function archiveAddRemove(addOrRemove) {
  emit(`archive-${addOrRemove}`, {
    mediaType: 'documents',
    id: props.documents[currentDocumentIndex.value].id,
  });
}
</script>

<template>
  <div class="flex flex-col items-center overflow-hidden">
    <div class="h-4/5 overflow-scroll">
      <VuePdfEmbed
        :source="documents[currentDocumentIndex || selectedItemIndex].url"
        :width="1080"
        class="w-full overflow-scroll"
      />
    </div>

    <archive-controls
      :allowArchiveRemove="allowArchiveRemove"
      mediaType="documents"
      @archive-add="archiveAddRemove('add')"
      @archive-remove="archiveAddRemove('remove')"
    ></archive-controls>

    <carousel-controls
      :total-slides="documents.length"
      :current-slide="currentDocumentIndex + 1"
      media-type="DOCUMENTS"
      @previous="goPrevious()"
      @next="goNext()"
      @close="$emit('close-preview')"
    />
  </div>
</template>
