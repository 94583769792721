import gql from 'graphql-tag';

export const meetingSuggestMutation = gql`
mutation meeting ($profile: ScreenProfile!, $meetingId: ID!, $suggestion: String!) {
  meeting {
    suggestScreen (profile: $profile, meetingId: $meetingId, suggestion: $suggestion) {
      id
      message
      success
    }
  }
}`