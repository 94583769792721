import gql from 'graphql-tag';

export const appConnectionsScreenQuery = gql`
query appConnectionsScreen {
  appConnectionsScreen {
      ... on AppExternalActivity {
          icon
          name
          institution {
            id
          }
      }
  }
}`;