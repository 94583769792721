<script setup>
import { getStartDay } from '@utils/helpers.js';
import { capitalize } from 'lodash';
import { computed } from 'vue';
import i18n from '@i18n';

const props = defineProps({
  date: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
  shouldShowDate: {
    type: Boolean,
    default: true,
    required: false,
  },
  shouldShowMediaCount: {
    type: Boolean,
    default: false,
    required: false,
  },
  videosCount: {
    type: Number,
    required: false,
  },
  picturesCount: {
    type: Number,
    required: false,
  },
  mediaCount: {
    type: Number,
    required: false,
  },
});

const mediaText = computed(() => {
  const hasVideos = props.videosCount > 0;
  const hasPictures = props.picturesCount > 0 || props.mediaCount > 0;
  const totalPicturesCount = props.picturesCount + props.mediaCount;
  const totalMediaCount = totalPicturesCount + props.videosCount;

  if (!hasPictures && !hasVideos) {
    return null;
  }

  switch (true) {
    case hasPictures && hasVideos:
      return `${totalMediaCount} ${i18n.global.t('global.imagesAndVideos')}`;
    case hasPictures:
      return `${totalPicturesCount} ${i18n.global.t('global.images')}`;
    case hasVideos:
      return `${props.videosCount} ${i18n.global.t('ourHome.videos')}`;
    default:
      return null;
  }
});
</script>

<template>
  <p class="h700 line-clamp-2 text-white">{{ title }}</p>

  <p
    v-if="date && shouldShowDate"
    class="b500 truncate text-white flex items-center mt-1"
  >
    <fa-icon :icon="['far', 'fa-clock']" class="size-[18px] mr-2" />
    {{ capitalize(getStartDay(date)) }}
  </p>

  <p
    v-if="shouldShowMediaCount"
    class="b500 truncate text-white flex items-center mt-1"
  >
    <fa-icon :icon="['fas', 'photo-video']" class="size-[18px] mr-2" />
    {{ mediaText }}
  </p>
</template>
