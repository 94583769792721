<script setup>
import DaySelector from '@components/workSchedule/DaySelector.vue';
import { useStore } from 'vuex';
import {
  isWithinInterval,
  parseISO,
  areIntervalsOverlapping,
  addDays,
  subDays,
} from 'date-fns';
import { toDate } from 'date-fns-tz';
import constants from '@utils/constants';
import { get, isEmpty } from 'lodash';
import { ref, computed, watch, onUnmounted, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
import { format } from '@utils/date-fns';

const store = useStore();
const route = useRoute();

const icon = ref({
  morgen: 'sunrise',
  eftermiddag: 'sun',
  aften: 'sunset',
  nat: 'moon-stars',
});

const selectedDay = ref(new Date());
const today = ref(new Date());

onBeforeMount(() => {
  store.dispatch('workSchedule/getWorkSchedules', { useCache: true });
  store.dispatch('workSchedule/setType', 'SIMPLE_WORK_SCHEDULE');

  if (!isEmpty(workSchedule.value)) {
    scrollToInterval();
  }
});

onUnmounted(() => {
  setTimeout(() => {
    store.dispatch('workSchedule/setSelectedShift', {});
  }, 300); // wait for overlay to close before resetting store value
});

const workSchedule = computed(() => {
  return store.getters['workSchedule/all'];
});

const departmentSettings = computed(() => {
  return store.getters['department/settings'];
});

const pinValidation = computed(() => {
  return store.getters['profiles/pinValidation'];
});

const activeOverlay = computed(() => {
  return store.getters['general/activeOverlay'];
});

const institutionSettings = computed(() => {
  return store.getters['institution/settings'];
});

const departmentId = computed(() => {
  return store.getters['department/id'];
});

const selectedShift = computed(() => {
  return store.getters['workSchedule/selectedShift'];
});

const moduleColor = computed(() => {
  return route.meta.color;
});

const shownDays = computed(() => {
  // If the department has checked overwrite institution setting it will be in the shift array.
  const matchingDepartment = institutionSettings.value?.shift?.find(
    (settings) => settings.departmentId == departmentId.value
  );

  return (
    get(
      matchingDepartment,
      'daysForward',
      departmentSettings.value.shift.daysForward
    ) - 1
  ); // Subtract 1 to include the day today
});

const dateInterval = computed(() => {
  return {
    from: subDays(today.value, shownDays.value),
    to: addDays(today.value, shownDays.value),
  };
});

const editAction = computed(() => {
  return activeOverlay.value.data.action;
});

const intervals = computed(() => {
  return departmentSettings.value.simpleSchedulePlan
    .sort((a, b) => new Date(a.starttime) - new Date(b.starttime))
    .map((interval) => {
      const formattedSelectedDay = format(selectedDay.value, 'yyyy-MM-dd');

      const intervalStartDateTime = new Date(
        format(interval.starttime, `${formattedSelectedDay} HH:mm:00`)
      );

      const intervalEndDateTime = new Date(
        format(interval.endtime, `${formattedSelectedDay} HH:mm:00`)
      );

      return {
        ...interval,
        shifts: getShiftsInInterval(intervalStartDateTime, intervalEndDateTime),
        active: isWithinInterval(new Date(), {
          start: interval.starttime,
          end: interval.endtime,
        }),
      };
    });
});

watch(pinValidation, (pinData) => {
  if (!pinData.validPin || !pinData.pin) return;

  if (editAction.value === 'cancel') {
    store.dispatch('workSchedule/shiftUpdate', {
      shiftId: selectedShift.value.id,
      dates: dateInterval.value,
      cancel: true,
    });
  } else if (editAction.value === 'reactivate') {
    store.dispatch('workSchedule/shiftUpdate', {
      shiftId: selectedShift.value.id,
      dates: dateInterval.value,
      cancel: false,
    });
  }

  if (editAction.value === 'replace') {
    store.dispatch(
      'workSchedule/setReplaceWithEmployeeId',
      activeOverlay.value.data.employee.id
    );

    store.dispatch('workSchedule/shiftUpdate', {
      shiftId: selectedShift.value.id,
      dates: dateInterval.value,
      cancel: false,
      employeeId: activeOverlay.value.data.employee.id,
    });
  }

  if (editAction.value === 'delete') {
    store.dispatch('workSchedule/shiftDelete', {
      shiftId: selectedShift.value.id,
      dates: dateInterval.value,
    });
  }
});

watch(workSchedule, () => {
  if (selectedShift.value.id === undefined) return;

  workSchedule.value.find((shift) => {
    if (shift.id === selectedShift.value.id) {
      store.dispatch('workSchedule/setSelectedShift', shift);
      store.dispatch('general/setActiveOverlay', {
        name: 'work-schedule-entry',
        data: { ...shift },
      });
    }
  });
});

function getShiftsInInterval(start, end) {
  return workSchedule.value.filter((shift) => {
    const shiftStartDay = format(shift.start, 'yyyy-MM-dd');
    const shiftEndDay = format(shift.end, 'yyyy-MM-dd');
    const formattedSelectedDay = format(selectedDay.value, 'yyyy-MM-dd');

    if (
      shiftStartDay !== formattedSelectedDay &&
      shiftEndDay !== formattedSelectedDay
    )
      return false;

    const shiftStart = toDate(shift.start, { timeZone: constants.timezone });
    const shiftEnd = toDate(shift.end, { timeZone: constants.timezone });

    return areIntervalsOverlapping(
      { start: new Date(shiftStart), end: new Date(shiftEnd) },
      { start: new Date(start), end: new Date(end) }
    );
  });
}

function getLabelColors(interval) {
  return isNowWithinInterval(interval.starttime, interval.endtime)
    ? `bg-${moduleColor.value} text-white`
    : 'bg-white';
}

function isNowWithinInterval(startSetting, endSetting) {
  return isWithinInterval(today.value, {
    start: parseISO(startSetting),
    end: parseISO(endSetting),
  });
}

function showSingleShift(shift) {
  store.dispatch('workSchedule/setSelectedShift', shift);

  store.dispatch('general/setActiveOverlay', {
    name: 'work-schedule-entry',
    data: shift ? { ...shift } : { ...selectedShift.value },
  });
}

function scrollToInterval() {
  const element = document.querySelector('#scrollHere');
  if (!element) return;

  element.scrollIntoView({ behavior: 'smooth' });
}

function handleSelectedDay(payload) {
  selectedDay.value = payload;
}
</script>

<template>
  <div class="flex flex-col pb-48 h-initial min-h-full">
    <div
      v-for="interval in intervals"
      :id="
        isNowWithinInterval(interval.starttime, interval.endtime)
          ? 'scrollHere'
          : ''
      "
      :key="interval.name"
      class="flex flex-col justify-center items-center bg-charcoal mt-16 mx-16 p-8 rounded-xl bg-opacity-5"
    >
      <div
        class="w-max rounded-lg shadow-lg px-12 py-5 text-3xl font-semibold"
        :class="getLabelColors(interval)"
      >
        <fa-icon class="pr-1" :icon="['far', `${icon[interval.icon]}`]" />
        {{ interval.name }}
      </div>
      <div
        class="flex flex-wrap pt-12 pb-6 space-between justify-center grid-cols-3 flex-grow"
      >
        <div
          v-for="(shift, index) in interval.shifts"
          :key="index"
          class="relative m-2"
        >
          <img
            :src="shift.employee.displayImage"
            alt="Employee"
            class="w-36 h-36 rounded-full border-8 shadow-m border-white object-cover"
            :class="
              interval.active && !shift.cancelled ? '' : 'filter grayscale'
            "
            @click="showSingleShift(shift)"
          />

          <!-- TODO: Set active interval in the mapping of intervals instead -->
          <div
            v-if="interval.active"
            :class="`bg-${moduleColor}`"
            class="absolute right-0 bottom-1 inline-flex rounded-full h-12 w-12 border-4 border-white"
          />
          <div
            v-if="interval.active && shift.cancelled"
            class="bg-warning absolute w-12 h-12 rounded-full flex items-center justify-center right-0 bottom-1 border-4 border-white"
          >
            <fa-icon class="text-white text-xl" :icon="['fas', 'times']" />
          </div>
        </div>
      </div>
    </div>

    <daySelector
      class="fixed bottom-80"
      @handle-selected-day="handleSelectedDay"
    />
  </div>
</template>

<style>
.h-initial {
  height: initial !important;
}
</style>
