import colors from '@utils/colors.js';

export default [
  {
    name: 'profile-select',
    backdropColor: colors.charcoal,
    canClose: true,
    title: 'profileSelect.header',
  },
  {
    name: 'participants-list',
  },
  {
    name: 'enter-pin',
    backdropColor: colors.charcoal,
    canClose: true,
  },
  {
    name: 'profiles-list',
    canClose: true,
  },
  {
    name: 'agenda',
    canClose: true,
  },
  {
    name: 'suggestions',
    canClose: true,
    title: 'meeting.suggestions',
  },
  {
    name: 'write-suggestion',
    canClose: true,
    title: 'meeting.writeSuggestion',
  },
  {
    name: 'work-schedule-entry',
    canClose: true,
    backdropColor: colors.charcoal,
  },
  {
    name: 'new-booking',
    canClose: true,
  },
  {
    name: 'new-slotted-booking',
    canClose: true,
  },
  {
    name: 'new-booking-description',
    canClose: true,
  },
  {
    name: 'new-booking-participants',
    canClose: true,
    title: 'booking.selectParticipantsTitle',
  },
  {
    name: 'replace-shift-employee',
    canClose: true,
    title: 'scheduleShift.replaceShift',
  },
  {
    name: 'replace-shift-confirmation',
    canClose: true,
  },
];
